.page-header
{
    background-color: $bodyBgColorLight;
    height: 78px;
    margin-bottom: 10px;
    box-shadow: 1px 1px 15px #ddd;


    @media #{$query-desktop} {
        height: 78px;
    }

    .container
    {

        position: relative;
        @extend .clearfix;
    }

    .logo
    {
        width: 196px;
        padding: 11px 0 0 0;

        float:left;
        @media #{$query-desktop} {

            padding: 13px 0 0 0;
        }

        a
        {
            display: block;
            cursor: pointer;

            img
            {
                display: block;
                width: 100%;
            }
        }
    }

    .menu {
        float:right;
        margin-right: 50px;
        padding-top: 23px;
        ul {
            li {
                float:left;
                padding: 10px 15px;
                border-left:1px #eaeaea solid;
                cursor: pointer;
                &:hover {
                    a {
                        color:orange;
                    }
                }

                &:first-child {
                    border: 0;
                }

                a {
                    color:rgba(53,53,53,.8);
                    font-size: 16px;
                    font-weight: 700;

                }
            }
        }
    }
    .widget {
        float:right;
        top: 33px;
        right: 36px;
        width: 100px;
        font-size: 17px;
        width: 100px;
        padding-top: 30px;
        color:#b1b1b1;
        margin-right:5px;
        .weather {

            font-weight: 700;
            display: block;
            float: right;
            i {
                margin-right: 3px;
            }
            .icon {
                float:left;
                margin-top:-4px;
                opacity: 0.5;
            }
            .text {
                font-size:18px;
                float:left;
                color:#DCDCEC;
            }
        }

        .search {

            float:left;
            border-right:1px #eaeaea solid;
            padding-right: 17px;
            &:hover {
                cursor:pointer;
            }

            .search-form {
                display: none;
                position: fixed;
                width: 100%;
                height: 100%;
                background: black;
                opacity: 0.9;
                z-index: 9999;
                top:0;
                left:0;

                .font-cancel {
                    position: fixed;
                    top:20px;
                    right: 30px;
                    font-size:30px;
                    font-weight:bold;
                }

                .searchf {
                    width: 50%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    -webkit-transform: translate(-50%,-50%);
                    -ms-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);

                    input {
                        color: #fff;
                        width: 100%;
                        border: 0;
                        font-size: 30px;
                        line-height: 42px;
                        background-color: transparent;
                        border-bottom: 1px solid rgba(255,255,255,.2);
                        padding-right: 55px;
                    }

                    button {
                        position: absolute;
                        right: 0;
                        top: 2px;
                        font-size: 30px;
                        border: 0;
                        background-color: transparent;
                        color: #fff;
                    }
                }
            }
        }
    }



    .nav
    {
        float: right;
        display: none;

        @media #{$query-desktop} {
            display: block;
        }

        ul
        {
            li
            {
                float: left;
                a
                {
                    cursor: pointer;
                    display: block;
                    line-height: 55px;
                    color: $textColor;
                    padding: 0 20px;
                    font-size: 16px;
                }
            }
        }
    }
}

.admin {
    &.baglantilar {

        a {
            float:left;
            color:black;
            display:block;
            padding: 5px;
        }
    }
}