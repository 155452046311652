#slider {
    position: relative;

    .newsSlider {
        width: 620px;
        float:left;

        .slick-slide {
            a {
                position: relative;
                img {
                    width:620px;
                    height: 315px;
                    z-index:999;
                }

                .title {
                    display: none;
                    position: absolute;
                    font-size:20px;
                    bottom:0;
                    z-index:9999;
                    width: 620px;
                    text-align: left;
                    font-size: 28px;
                    font-weight: bold;
                    color: white;
                    background: rgba(27, 27, 27, 0.74);
                    text-shadow: 1px 1px 3px #000000;
                    padding:10px;

                    &.yenisafak,
                    &.t24,
                    &.haberturk,
                    &.sol,
                    &.cumhuriyet
                    {
                        display: block;
                    }
                }
            }
        }
        .slick-dots {
           li {
               button {
                   &.active {
                     background: #d4d4d4;
                   }
               }
           }
        }


    }

    #slideLogo {
        position: absolute;
        top: 18px;
        right: 390px;
        span {
            opacity: 0.8;

        }
    }

    .newsLatest {
        width : 340px;

        height:347px;
        float:right;

        .ads {
            width: 336px;
            height: 280px;
            /* margin: 0 auto; */
            margin-top: 5px;
            margin-right: 5px;
        }

        .info {
            padding-top: 15px;
            a {
                span {
                    float: left;
                    opacity: 1;
                }
                .text {
                    float: left;
                    margin-left: 12px;
                    color: rgba(27, 26, 26, 0.67);
                    font-size:12px;
                    padding-top: 10px;
                }
                &:hover {
                    .text {
                        text-decoration: underline;
                    }
                }
            }

        }

        li {
            height: 86px;
            border-bottom: 1px #e2e2e2 solid;
            border-top: 1px #fffdfd solid;
            padding: 6px;

            &:last-child {
                border-bottom: 0;
            }
            &:hover {
              a {
                color: #da2128;
              }
            }
            a {
                color:black;
            }

            .title {
              padding: 15px 20px 15px 2px;
              overflow: hidden;
              white-space: normal;
              padding-right: 0;
              font-weight: 700;
              font-size:14px;
              font-family: sans-serif;
            }

            .picture {
                display: inline-block;
                width: 100px;
                height: 69px;
                overflow: hidden;
                background-color: rgba(0, 0, 0, 0.25);
                float:right;

                img {
                  width: 100%;
                  height: auto;
                }
            }

        }

    }

    .newsFour {
        margin-top:20px;
        ul {
            li {
                float: left;
                margin-right: 20px;
                width: 220px;
                height: 159px;
                padding: 0;
                position: relative;

                &:hover {
                    a {
                      color: #da2128;
                    }
                }

                a {
                    color: black;
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                }

                img {
                  width: 100%;
                  height: auto;
                }
                .picture {
                    margin: 0;
                    width: 220px;
                    height: 159px;
                    float: left;
                    display: inline-block;
                    overflow: hidden;
                }

                .content {
                    display: table;
                    position: absolute;
                    z-index: 10;
                    width: 180px;
                    height: 50px;
                    margin-left: 20px;
                    background-color: #fff;
                    font-size: 15px;
                    border-bottom: solid 1px #e0e0e0;
                    bottom: 15px;
                    text-align: center;
                    padding: 5px;
                    .text {
                        position: static;
                        display: table-cell;
                        vertical-align: middle;
                        text-align: center;
                    }


                }
                &:nth-child(n+4) {
                  margin-right: 0;
                }

            }
        }
    }


    .loader {
        width: 100%;
        height: 100%;
        background: #ffffff;
        z-index: 999;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.9;
    }
}


.uil-spin-css {
    background: 0 0;
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    margin-top: 70px;
}
@-webkit-keyframes uil-spin-css {
    0% {
        opacity: 1;
        -ms-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
    }
    100% {
        opacity: 0.1;
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes uil-spin-css {
    0% {
        opacity: 1;
        -ms-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
    }
    100% {
        opacity: 0.1;
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}
@-moz-keyframes uil-spin-css {
    0% {
        opacity: 1;
        -ms-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
    }
    100% {
        opacity: 0.1;
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}
@-ms-keyframes uil-spin-css {
    0% {
        opacity: 1;
        -ms-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
    }
    100% {
        opacity: 0.1;
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}
@-moz-keyframes uil-spin-css {
    0% {
        opacity: 1;
        -ms-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
    }
    100% {
        opacity: 0.1;
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes uil-spin-css {
    0% {
        opacity: 1;
        -ms-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
    }
    100% {
        opacity: 0.1;
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}
@-o-keyframes uil-spin-css {
    0% {
        opacity: 1;
        -ms-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
    }
    100% {
        opacity: 0.1;
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes uil-spin-css {
    0% {
        opacity: 1;
        -ms-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
    }
    100% {
        opacity: 0.1;
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}
.uil-spin-css > div {
    width: 24px;
    height: 24px;
    margin-left: 4px;
    margin-top: 4px;
    position: absolute;
}
.uil-spin-css > div > div {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: #000;
}
.uil-spin-css > div:nth-of-type(1) > div {
    -ms-animation: uil-spin-css 1s linear infinite;
    -moz-animation: uil-spin-css 1s linear infinite;
    -webkit-animation: uil-spin-css 1s linear infinite;
    -o-animation: uil-spin-css 1s linear infinite;
    animation: uil-spin-css 1s linear infinite;
    -ms-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -webkit-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
}
.uil-spin-css > div:nth-of-type(1) {
    -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
}
.uil-spin-css > div:nth-of-type(2) > div {
    -ms-animation: uil-spin-css 1s linear infinite;
    -moz-animation: uil-spin-css 1s linear infinite;
    -webkit-animation: uil-spin-css 1s linear infinite;
    -o-animation: uil-spin-css 1s linear infinite;
    animation: uil-spin-css 1s linear infinite;
    -ms-animation-delay: 0.12s;
    -moz-animation-delay: 0.12s;
    -webkit-animation-delay: 0.12s;
    -o-animation-delay: 0.12s;
    animation-delay: 0.12s;
}
.uil-spin-css > div:nth-of-type(2) {
    -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
}
.uil-spin-css > div:nth-of-type(3) > div {
    -ms-animation: uil-spin-css 1s linear infinite;
    -moz-animation: uil-spin-css 1s linear infinite;
    -webkit-animation: uil-spin-css 1s linear infinite;
    -o-animation: uil-spin-css 1s linear infinite;
    animation: uil-spin-css 1s linear infinite;
    -ms-animation-delay: 0.25s;
    -moz-animation-delay: 0.25s;
    -webkit-animation-delay: 0.25s;
    -o-animation-delay: 0.25s;
    animation-delay: 0.25s;
}
.uil-spin-css > div:nth-of-type(3) {
    -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
}
.uil-spin-css > div:nth-of-type(4) > div {
    -ms-animation: uil-spin-css 1s linear infinite;
    -moz-animation: uil-spin-css 1s linear infinite;
    -webkit-animation: uil-spin-css 1s linear infinite;
    -o-animation: uil-spin-css 1s linear infinite;
    animation: uil-spin-css 1s linear infinite;
    -ms-animation-delay: 0.37s;
    -moz-animation-delay: 0.37s;
    -webkit-animation-delay: 0.37s;
    -o-animation-delay: 0.37s;
    animation-delay: 0.37s;
}
.uil-spin-css > div:nth-of-type(4) {
    -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
}
.uil-spin-css > div:nth-of-type(5) > div {
    -ms-animation: uil-spin-css 1s linear infinite;
    -moz-animation: uil-spin-css 1s linear infinite;
    -webkit-animation: uil-spin-css 1s linear infinite;
    -o-animation: uil-spin-css 1s linear infinite;
    animation: uil-spin-css 1s linear infinite;
    -ms-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
}
.uil-spin-css > div:nth-of-type(5) {
    -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
}
.uil-spin-css > div:nth-of-type(6) > div {
    -ms-animation: uil-spin-css 1s linear infinite;
    -moz-animation: uil-spin-css 1s linear infinite;
    -webkit-animation: uil-spin-css 1s linear infinite;
    -o-animation: uil-spin-css 1s linear infinite;
    animation: uil-spin-css 1s linear infinite;
    -ms-animation-delay: 0.62s;
    -moz-animation-delay: 0.62s;
    -webkit-animation-delay: 0.62s;
    -o-animation-delay: 0.62s;
    animation-delay: 0.62s;
}
.uil-spin-css > div:nth-of-type(6) {
    -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
}
.uil-spin-css > div:nth-of-type(7) > div {
    -ms-animation: uil-spin-css 1s linear infinite;
    -moz-animation: uil-spin-css 1s linear infinite;
    -webkit-animation: uil-spin-css 1s linear infinite;
    -o-animation: uil-spin-css 1s linear infinite;
    animation: uil-spin-css 1s linear infinite;
    -ms-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    -webkit-animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    animation-delay: 0.75s;
}
.uil-spin-css > div:nth-of-type(7) {
    -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
}
.uil-spin-css > div:nth-of-type(8) > div {
    -ms-animation: uil-spin-css 1s linear infinite;
    -moz-animation: uil-spin-css 1s linear infinite;
    -webkit-animation: uil-spin-css 1s linear infinite;
    -o-animation: uil-spin-css 1s linear infinite;
    animation: uil-spin-css 1s linear infinite;
    -ms-animation-delay: 0.87s;
    -moz-animation-delay: 0.87s;
    -webkit-animation-delay: 0.87s;
    -o-animation-delay: 0.87s;
    animation-delay: 0.87s;
}
.uil-spin-css > div:nth-of-type(8) {
    -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
}

.sliderInner {
    min-height:300px;
    visibility: hidden;
}