.content-nav
{
    position: relative;

    #siteLogo {
        height: 55px;
        margin-top: 25px;
        background: white;
        padding: 5px;
        overflow: hidden;

        .slick-slide {
            border-right: 1px #f9f9f9 solid;
            border-left: 1px #f1f1f1 solid;
        }

    }

    .swipe-nav
    {
        position: relative;
        overflow: hidden;
        height: 48px;

        @media #{$query-tablet} {
            height: 57px;
        }

        &:after
        {
            content: "";
            display: block;
           // @include linear-gradient(to right, #FFF, rgba(255,255,255,0),$fallback: rgba(255,255,255,0));
            @include position(absolute,0 65% 3px 0px);
            z-index: index($elements,content-nav-shadow);
        }
        &:before
        {
            content: "";
            display: block;
           // @include linear-gradient(to left, #FFF, rgba(255,255,255,0),$fallback: rgba(255,255,255,0));
            @include position(absolute,0 0px 3px 65%);
            z-index: index($elements,content-nav-shadow);
        }

        @media #{$query-tablet} {
            &:after
            {
                left: 50px;
                bottom: 0;
            }
            &:before
            {
                right: 50px;
                bottom: 0;
            }
        }



        .list
        {
            @extend .clearfix;
            padding: 5px 0;
            overflow: hidden;
            white-space: nowrap;
            position: absolute;

            li
            {
                float: none;
                padding: 0 18px;
                display: inline-block;

                a
                {
                    display: block;

                    img
                    {
                        height: 35px;

                        @media #{$query-tablet} {
                            height: 44px;
                        }
                    }
                }
            }
        }
    }
    .line
    {
        height: 3px;
        background-color: $colorAction;
        @include position(absolute,null 0 0 0);
        z-index: index($elements,content-nav-line);

        &.after
        {
            display: block;
            @extend %font;
            content: font-char(arrow-up);
            color: $colorAction;
            font-size: 18px;
            @include position(absolute,null null -2px 50%);
            line-height: 16px;
            margin-left: -10px;

            @media #{$query-tablet} {
                font-size: 21px;
            }
        }
    }

    .nav-btn
    {
        @include position(absolute,0 null 0 null);
        z-index: index($elements,content-nav-btn);
        background-color: white;
        border:none;
        color: $colorAction;
        font-size: 24px;
        padding: 0;
        margin: 0;
        width: 50px;
        cursor: pointer;
        outline: none;
        display: none;
        margin-bottom: 10px;

        @media #{$query-tablet} {
            display: block;
        }

        &:after
        {
            @extend %font;
        }

        &.left
        {
            left: 0;

            &:after
            {
                content: font-char(left-btn);
            }
        }

        &.right
        {
            right: 0;

            &:after
            {
                content: font-char(right-btn);
            }
        }
    }


}
