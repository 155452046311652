a
{
    &.active {
        span {
            @include opacity(1);
            zoom:1;
        }
    }

    span {
      display: block;
      text-indent: -9999px;
      background-image: url('../img/sprite.png');
      @include opacity(0.2);


      &.ensonhaber {
        background-position: -182px -30px;
        width: 43px;
        height: 37px;
      }

      &.superhaber {
        background-position: -397px -210px;
        width: 86px;
        height: 38px;
      }
      div.logo {
        display: block;
        text-indent: -9999px;
        background-image: url('../img/sprite.png');
        float: left;
      }
      &.yenisafak {
        background-position: -321px -103px;
        width: 95px;
        height: 38px;
      }
      &.memurlar {
        background-position: -65px -97px;
        width: 99px;
        height: 38px;
      }
      &.haber7 {
        background-position: -466px -27px;
        width: 81px;
        height: 38px;
      }
      &.haberturk {
        background-position: -250px -29px;
        width: 69px;
        height: 38px;
      }
      &.hurriyet {
        background-position: -348px -29px;
        width: 80px;
        height: 38px;
      }
      &.milliyet {
        background-position: -59px -29px;
        width: 86px;
        height: 38px;
      }
      &.posta {
        background-position: -455px -103px;
        width: 88px;
        height: 38px;
      }
      &.sozcu {
        background-position: -574px -27px;
        width: 80px;
        height: 38px;
      }
      &.sabah {
        background-position: -680px -29px;
        width: 83px;
        height: 38px;
      }
      &.samanyolu {
        background-position: -91px -119px;
        width: 51px;
        height: 38px;
      }
      &.mynet {
        background-position: -212px -104px;
        width: 62px;
        height: 38px;
      }

      &.ntvspor {
        background-position: -218px -119px;
        width: 86px;
        height: 38px;
      }
      &.canlisonuc {
        background-position: -2px -192px;
        width: 86px;
        height: 38px;
      }
      &.ligtv {
        background-position: -397px -119px;
        width: 44px;
        height: 38px;
      }
      &.fanatik {
        background-position: -444px -119px;
        width: 86px;
        height: 38px;
      }
      &.fotomac {
        background-position: -94px -206px;
        width: 116px;
        height: 38px;
      }
      &.tumspor {
        background-position: -215px -210px;
        width: 116px;
        height: 38px;
      }
      &.kralspor {
        background-position: -533px -119px;
        width: 47px;
        height: 38px;
      }
      &.amk {
        background-position: -307px -119px;
        width: 86px;
        height: 38px;
      }

      &.akit {
        background-position: -598px -103px;
        width: 82px;
        height: 38px;
      }


      &.vahdet {
        background-position: -706px -103px;
        width: 72px;
        height: 38px;
      }


      &.odatv {
        background-position:  -814px -102px;
        width: 44px;
        height: 42px;
      }


      &.t24 {
        background-position: -65px -167px;
        width: 55px;
        height: 38px;
      }

      &.sol {
        background-position:  -203px -167px;
        width: 44px;
        height: 38px;
      }

      &.ortadogu {
        background-position: -302px -167px;
        width: 100px;
        height: 38px;
      }

      &.cumhuriyet {
        background-position: -432px -166px;
        width: 97px;
        height: 38px;
      }

      &.vatan {
        background-position: -572px -167px;
        width: 82px;
        height: 38px;
      }

      &.aksam {
        background-position: -706px -167px;
        width: 84px;
        height: 38px;
      }

      &.star {
        background-position: -837px -167px;
        width: 65px;
        height: 38px;
      }



    }
  }