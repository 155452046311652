.linksPage {
    background: transparent !important;
    .title {
        h2 {
            margin-bottom: 10px;
            font-size:30px;
            color:#494c4f;
        }



        .desc {
            margin-top:0;
            color:#7b7b7b;
        }

    }


    .sub {

        h3 {
            color:#494c4f;
            font-size:17px;
            margin-top:5px;
            border-bottom:1px #ececec solid;
            background: #dcdada;
            padding: 9px;
            margin-left: -5px;
            margin-right: -5px;
            a { color:#494c4f; }
        }

        clear: both;
        margin-bottom: 20px;
        padding: 5px;
        @include box-shadow(1px, 2px, 3px, #bbb);
        background: #fbfbfb;
        padding-bottom: 0;
        padding-top: 0;
        ul {

            &:after {
                content: ".";
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
            }
            li {
                text-align: center;
                width: 161px;
                height: 45px;
                float: left;
                border-right:1px #e2e2e2 solid;
                border-bottom:1px #e2e2e2 solid;
                padding: 5px;

                &:nth-child(6n) {
                    border-right: 0;
                }

                span {
                    background: none;
                    text-indent: 0;
                    opacity: 1;
                    float:left;
                    margin-right: 5px;
                    width: 32px;
                    height: 32px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height:100%;
                    }
                }

                a {
                    .text {
                        color:#494c4f;
                        float:left;
                        width: 108px;
                        text-align: left;
                    }
                }
                &:hover {
                    background: #eaeaea;
                }
            }
        }
    }

    .othercategory {
        a {
            color:#494c4f;
            display: block;
            float: left;
            padding: 5px;
            border:1px solid #e4e4e4;
            margin: 4px;

            &:hover {
                background: #eaeaea;
            }
        }
    }
}
