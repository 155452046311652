// Opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    // IE8 filter
    $opacity-ie: ($opacity * 100);
    filter: #{alpha(opacity=$opacity-ie)};
}

@mixin getPercentage($column) {
    width: percentage((100/$column)/100);
}

.clearfix
{
	@include clearfix();
}

%uiElementBoxShadow
{
    box-shadow: 0 0 2px 0 rgba(0,0,0,.14), 0 1px 2px 0 rgba(0,0,0,.14);
}

%float-left
{
	float: left;
}

%default-transition
{
	@include transition(all,.2s);
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin reset-span() {
  background: none !important;
  text-indent:0 !important;
  @include opacity(1);
}