.container
{
	margin: 0 auto;
	width: 980px;
	background: white;


	&.content {
		margin-bottom:25px;
		@include box-shadow(1px, 2px, 3px, #bbb);
	}

	&.newspaper {
		background: transparent !important;
	}

	@media #{$query-tablet} {

	}

	@media #{$query-desktop} {

	}


}

.centerContent {
	text-align: center;
}