.footer {
    text-align: center;
    margin-top: 60px;
    background: #ffffff;
    height: 250px;
    width: 100%;
    padding-top: 10px;
    border-top: 1px #e8e8e8 solid;

    .logo {
        width: 198px;
        margin: 0 auto;
    }

    .text {
        margin:0 auto;
        width: 980px;
        text-align: center;
        margin-top:20px;
        font-size:12px;
        color:#777777;
    }

    .icon {
        color:#777777;
        text-align: center;
        font-size: 24px;
        margin-top: 10px;
        .font-facebook {
            color:#3b5998;
        }
        .font-twitter {
            color:#55acee;
        }
    }

    .menu {
        margin-right: 50px;
        padding-top: 23px;
        width: 980px;
        margin: 0 auto;

            a {
                padding: 10px 15px;
                border-left:1px #eaeaea solid;
                cursor: pointer;
                font-size:15px;

                &:hover {
                  text-decoration: underline;
                }

                color :#777777;

                &:first-child {
                    border: 0;
                }

            }

    }
}