.otherlinks {
     background-color: transparent !important;
     margin-top:30px;

    .newsLinks {
        &.category {
            width: 100%;

            .title {
              font-weight:bold;
              font-size:20px;
              border-bottom:1px silver solid;
              margin-top: 5px; margin-bottom:5px;
            }

            > li {
                width: 100%;
                float:left;

                &:first-child {
                  width: 174px;
                  background-color: #dedede;
                  height: 510px;

                  a {
                    display: block;
                    margin-right: 28px;
                    float:left;

                    &:nth-child(2n+1) {
                      margin-right: -6px;
                    }
                    &.active {
                      font-weight: bold;
                    }
                    span {
                      background: none;
                      text-indent: 1px;
                      font-size: 18px;
                      opacity: 0.6;
                    }
                  }
                }

                &:last-child {
                  width: 796px;
                  margin-left: 10px;

                  > a {
                      width: 258px;
                      float:left;
                      margin-right: 1px;

                      &:nth-child(3n+1) {
                        margin-right: -2px;
                      }

                      span {
                        background: none;
                        text-indent: 1px;
                        font-size: 18px;
                        opacity: 0.6;
                      }
                  }

                }

            }
        }

        > li {
            float:left;
            width: 188px;
            padding: 10px;

            &:nth-child(3) {
                  padding-right: 0;
            }


            h3 {
                margin: 0;
                height: 30px;
                margin-bottom: 10px;
                font-size: 1.1rem;
                line-height: 1.2rem;
                span {
                    padding-bottom: 7px;
                }
            }

            b {
                  display: block;
                  padding-bottom: 5px;
                  border-bottom: 1px #e8e8e8 solid;
            }
            .nano-content a,
            > a {

                  font-size: 0.9rem;
                  line-height: 1.2rem;
                  cursor: pointer;
                  color: black;
                  display: block;
                  width: 100%;
                  padding: 3px 0;
                  color: #1b1a1a;
                  position: relative;

                &:hover {
                    text-decoration: underline;
                }

                span {
                    position: absolute;
                    right: 25px;
                    top: 0;
                    background-repeat: no-repeat;
                    zoom: 0.6;
                    @include opacity(0.2);
                }
            }
        }

        .sub {
           box-shadow: 0 0 0 0;
          background: none;
          li {
            width: 191px;
            height: 100px;
            padding: 5px;
            &:nth-child(6n) {
              border-right: 1px #e2e2e2 solid;
            }

            &:nth-child(4n) {
              border-right: 0;
            }

            span {
              width: 100%;
              height: 44px;
              text-align: center;
              margin-bottom: 5px;
              img {
                width: 100px;
                height: 44px;
              }
            }

            .text {
                width: 100%;
                text-align: center;
            }
          }
        }

    }

}

.color-1 {
  border-bottom: solid #cb0001 1px;
  span {
    border-bottom: solid #cb0001 3px;
  }
}
.color-2 {
  border-bottom: solid #E7A300 1px;
  span {
    border-bottom: solid #E7A300 3px;
  }
}
.color-3 {
  border-bottom: solid #0988C5 1px;
  span {
    border-bottom: solid #0988C5 3px;
  }
}
.color-4 {
  border-bottom: solid #52A25D 1px;
  span {
    border-bottom: solid #52A25D 3px;
  }
}
.color-5 {
  border-bottom: solid #EC731A 1px;
  span {
    border-bottom: solid #EC731A 3px;
  }
}
.color-6 {
  border-bottom: solid #0E7682 1px;
  span {
    border-bottom: solid #0E7682 3px;
  }
}
.color-7 {
  border-bottom: solid #A600A0 1px;
  span {
    border-bottom: solid #A600A0 3px;
  }
}
.color-8 {
  border-bottom: solid #A99F91 1px;
  span {
    border-bottom: solid #A99F91 3px;
  }
}
.color-9 {
  border-bottom: solid #2196F3 1px;
  span {
    border-bottom: solid #2196F3 3px;
  }
}
.color-black {
  border-bottom: solid #505050 1px;
  span {
    border-bottom: solid #505050 3px;
  }
}

.boxshadow {
    background: white;
  margin-bottom: 20px;

  @include box-shadow(1px, 2px, 3px, #bbb);
}

.cities {
    width: 100%;
    background-color: #dedede;
    a {
        font-size: 0.8rem;
        line-height: 1.2rem;
        cursor: pointer;
        display: block;
        padding: 3px 0;
        color: #1b1a1a;
        position: relative;
        width: 103px;
        margin-left: 5px;
        float: left;
        &:hover {
          text-decoration: underline;
        }
    }
}