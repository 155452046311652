


.weather i[class*=" icon-"],
.weather i[class^=icon-] {
  display: block;
  width: 24px;
  height: 29px;
  background-image: url(../img/weather-sprite.png);
  background-position-x: 0
}

.weather .icon-acik {
  background-position-y: -145px
}

.weather .icon-az-bulutlu {
  background-position-y: -116px
}

.weather .icon-duman {
  background-position-y: -667px
}

.weather .icon-hafif-sagnak-yagisli,
.weather .icon-hafif-yagmurlu {
  background-position-y: -493px
}

.weather .icon-hafif-kar-yagisli {
  background-position-y: -464px
}

.weather .icon-yer-yer-saganak-yagisli {
  background-position-y: -493px
}

.weather .icon-karla-karisik-yagmurlu {
  background-position-y: -406px
}

.weather .icon-guneyli-kuvvetli-ruzgar {
  background-position-y: -551px
}

.weather .icon-sicak {
  background-position-y: -609px
}

.weather .icon-parcali-bulutlu {
  background-position-y: -87px
}

.weather .icon-pus {
  background-position-y: -638px
}

.weather .icon-saganak-yagisli,
.weather .icon-yagmurlu {
  background-position-y: -232px
}

.weather .icon-kar-yagisli {
  background-position-y: -290px
}

.weather .icon-dolu {
  background-position-y: -261px
}

.weather .icon-ruzgarli {
  background-position-y: -58px
}

.weather .icon-kuzeyli-kuvvetli-ruzgar {
  background-position-y: -522px
}

.weather .icon-soguk {
  background-position-y: -580px
}

.weather .icon-cok-bulutlu {
  background-position-y: -319px
}

.weather .icon-sis {
  background-position-y: -667px
}

.weather .icon-kuvvetli-saganak-yagisli,
.weather .icon-kuvvetli-yagmurlu {
  background-position-y: -232px
}

.weather .icon-yogun-kar-yagisli {
  background-position-y: -435px
}

.weather .icon-gokgurultulu-saganak-yagisli {
  background-position-y: -406px
}

.weather .icon-toz-veya-kum-firtinasi {
  background-position-y: -29px
}