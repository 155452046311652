$elements:  content-nav-line,
            content-nav-shadow,
            content-nav-btn;


//defaults
$colorBlack:        #000;
$colorWhite:        #fff;
$colorAction:      	#FFA837;
$colorActHover:     #e86e51;

$colorText:		    #747b7f;
$colorRed:          #dd313f;


//scaffolding
$textColor:         $colorBlack;
$linkColor:			$colorAction;
$buttonColor:		$colorAction;
$bodyBgColor:		#f3f3f3;
$bodyBgColorLight:  #FFF;


//forms
$colorInputBorder:			#bfbfbd;
$colorInputBorderHover:		darken($colorInputBorder,10%);
$colorInputBorderActive:	darken($colorInputBorder,30%);



//borders
$borderColor: 		#e5e9ec;
$borderActive: 		$colorAction;
$borderRadius: 		2px;



//responsive-layout
$screen-content:        980px;

$screen-small:			640px;
$screen-normal:			840px;

$query-tablet: 			"( min-width : #{$screen-small} )";
$query-desktop:			"( min-width : #{$screen-normal} )";

//fonts
$baseFontSize:      	14px;

$helveticaLT:'HelveticaNeueLTPro','Helvetica','Arial';
$helveticaProMdCn:'HelveticaNeueLTProMdCn','Helvetica','Arial';

$helveticaFontFamily: 'Helvetica','HelveticaNeueLTPro','Arial';
$baseFontFamily: $helveticaFontFamily;

