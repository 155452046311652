html
{
    font-family: $baseFontFamily;
    height: 100%;
}

body
{
    font-size: $baseFontSize;
    font-family: $baseFontFamily;
    // -webkit-tap-highlight-color: transparent;
    background-color: $bodyBgColor;
    height: 100%;
}

ul
{
    list-style: none;
    padding:0;
    margin: 0;
}

a
{
    color: $linkColor;
    text-decoration: none;
    //@extend %default-transition;

    &:hover
    {
        color: $colorActHover;
        text-decoration: none;
    }
}

input{
    &:focus{
        outline: none;
    }
}