.sitene-ekle {
    .left {
        float:left;
        width: 640px;
    }

    .right {
        float:right;
        width: 300px;
    }

    span.title {
        font-weight: bold;
        color:darkorange;
        margin: 10px 0;
        display: block;
        width: 100%;
    }

    .checkboxes {
        label {
            width: 100px;
            float:left;
            padding: 5px;
            padding-left: 0;

            input {
                margin-right: 5px;
            }
        }
    }

    .option {
        #width {
            float:left;
            width: 75px;
            input {
              width: 50px;
              padding: 5px;
            }
        }

        #height {
            float:left;
            width: 100px;
            input {
              width: 50px;
              padding: 5px;
            }
        }
        margin-bottom: 10px;
    }
    .code-result {
        width: 100%;
        padding: 5px;
        margin-top: 10px;
        height: 120px;
        border:1px silver solid;
        color:darkgray;
    }
    .submit {
        background: darkorange;
        padding: 5px;
        border: 1px orange solid;
        color: white;
        font-size:14px;
    }
}