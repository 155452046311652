.newsPaperDetail {
    max-width: 1050px;
    .font-cal {
      font-size: 19px;
    }
    .leftTitle {
      float:left;
    }

    .datesel {
        background: white;
        width: 135px;
        position: relative;
        height: 28px;
        i, input{
          color: #4c4c4c;
        }
        i {
          position: absolute;
          right: 5px;
          top:0;
        }
        input {
            width: 135px;
            position: absolute;
            left: 0;
            top:0;
            z-index:9999;
            background: transparent;
            &:hover {
                cursor: pointer;
            }
        }

    }
    .dateButton {

        padding: 6px;
        width: 105px;
        border:0;
        font-size:15px;

    }
    .archive {
        float:right;
        margin-top: 40px;

        .share {
          z-index: 99999;
          a {
            font-size: 30px;
            .font-facebook {
              color:#3b5998;
            }
            .font-twitter {
              color:#55acee;
            }
          }
        }
    }

    #gazete_big {
      clear: both;
      width: 100%;
      float: left;
      position: relative;
      .newsimg {

        &:hover {
          a {
            opacity: 0.7;
          }
        }
        a {
          opacity: 0;
          &:hover {
            opacity: 1;
          }
        }
        .next {
          position: absolute;
          top: 450px;
          right: 0;
          font-size: 20px;
          display: block;
          background: black;
          padding: 10px;
          z-index: 9999;
        }

        .prev {
          position: absolute;
          top: 450px;
          left: 0;
          font-size: 20px;
          display: block;
          background: black;
          padding: 10px;
          z-index: 9999;
        }


      }
    }

    #newsWidget {
        width: 300px;
        float:right;

        h1 {
            font-size: 20px;
        }


        .selectNews {
            margin-top: 100px;
            select {
                width: 100%;
                padding: 10px;
                font-size: 15px;
              -webkit-appearance:none;
              -moz-appearance:none;
              appearance:none;

              cursor:pointer;

            }
            @media screen and (-webkit-min-device-pixel-ratio:0) {
              select {padding-right:18px}
            }

            label {position:relative}
            label:after {
              content:'<>';
              font:11px "Consolas", monospace;
              color:#aaa;
              -webkit-transform:rotate(90deg);
              -moz-transform:rotate(90deg);
              -ms-transform:rotate(90deg);
              transform:rotate(90deg);
              right:8px; top:2px;
              padding:0 0 2px;
              border-bottom:1px solid #ddd;
              position:absolute;
              pointer-events:none;
            }
            label:before {
              content: '';
              right: 6px;
              top: 0px;
              width: 20px;
              height: 20px;
              background: #f8f8f8;
              position: absolute;
            }
            margin-bottom: 30px;
        }
        .nextNewsPaper {
            margin-top: 30px;
            .newsPaper {
              margin:0 auto;
              width: 167px;
              height: 198px;

              padding: 10px;
              border:1px #ececec solid;
              background: white ;

              .image {
                width: 144px;
                height: 175px;
                overflow: hidden;
              }
              img {
                width: 155px;
                height: auto;
                margin-top: -10px;
                overflow: hidden;
              }

              position: relative;

              a {

                .title {
                  position: absolute;
                  width: 134px;
                  font-size: 13px;
                  padding: 5px;
                  bottom: -10px;
                  left: 16px;
                  text-align: center;
                  color: black;
                  background: #f5f5f5;
                  @include box-shadow(1px, 2px, 3px, #bbb);
                }
              }

            }
        }
    }

}

.otherNewsPapers {
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 1px;
    a {
        border:1px solid #e4e1e1;
        font-weight: bold;
        font-size: 15px;
        display: block;
        float:left;
        width: 164px;
        padding: 10px;
        color: rgba(0, 0, 0, 0.67);
        font-weight: bold;
        margin-left: -1px;
        margin-bottom: -1px;
        &:hover {
            color:black;
        }

    }
}