form {
    padding: 10px;
}
input {
    padding: 5px;
    font-size:13px;
}
.control-group {
    margin-bottom: 20px;
}
.control-label {
    font-weight:bold;
    padding-bottom: 5px;
    display: block;
}

.code {
    font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
    width: 100%;
    background: #eaeaea;
    padding:10px;
    font-size:12px;
    border:1px #f4f4f4 solid;
}

.alert {
    display: block;
    width: 100%;
    padding: 10px;

    &.success {
        background: #0eb600;
        color: white;
        font-weight: bold;
        font-size:14px;
    }
}