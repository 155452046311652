.writer {
    margin-top:30px;

    .nav-btn {
        margin: 0 0 3px;
      color:#3299BB;
    }
    .line {
        background-color:#3299BB;
    }
    #writers {
        height: 60px;

        .writerin {
            padding: 5px;
            width:231px;

            .image {
                width: 70px;
                height: 47px;
                overflow: hidden;
                float:left;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .text {
                float: left;
                margin-left: 9px;
                color: #000;
                font-size: 12px;
                text-align: left;
                width: 156px;
                b {
                    display: block;
                    font-size:14px;
                }
                i {
                  color:#5f5f5f;
                }
            }

            &:first-child {
                margin-left: 45px;
            }
        }
    }
}