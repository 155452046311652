.newspaper {
    padding: 0px;

    .leftSide {
        float:left;
        width:250px;
        background: #dedede !important;
        padding: 10px;
    }

    .rightSide {
        float:right;
        width:712px;
        margin-left:10px;
    }

    .newsLinks {
        a {
            cursor:pointer;
            color:black;
            display: block;
            width: 100%;
            padding :3px 0;
            font-size:13px;
            color:#1b1a1a;
            position: relative;
            line-height:1.35em;

            &:hover {

                text-decoration: underline;
            }

            span {
              position: absolute;
              right: 25px;
              top: 0;
              background-repeat: no-repeat;
              zoom: 0.6;
              @include opacity(0.2);
            }
        }
    }

    .newspaperList {
        li {
            float: left;
            width: 167px;
            height: 273px;

            margin:0px 0px 25px 11px;
            padding: 10px;
            border:1px #ececec solid;
            background: white ;

            .image {
                width: 140px;
                height: 223px;
                overflow: hidden;
                position: relative;
                margin: 0 auto;
            }
            img {
                width: 155px;
                height: 248px;
                margin-top: 0px;
                overflow: hidden;
                position: absolute;
                left: -8px;
            }

            position: relative;

            a {
                .NewsPaperTitle {
                    left: 28px;
                    color: #000;
                    font-size: 13px;
                    padding-top: 5px;
                }
                .date {
                    font-size:11px;
                    color: rgba(0, 0, 0, 0.55);
                }
                 .title {
                     position: absolute;
                     width: 134px;
                     font-size: 13px;
                     padding: 5px;
                     bottom: -10px;
                     left: 16px;
                     text-align: center;
                     color: black;
                     background: #f5f5f5;
                     @include box-shadow(1px, 2px, 3px, #bbb);
                 }
            }
        }
    }

    .allNews {
        display: block;
        padding:10px;
        margin:0 auto;
        width:300px;
        font-size:17px;
        background: #ffa837;
        color:#272727;
        text-align: center;
        @include box-shadow(1px, 2px, 3px, #bbb);
        margin-top:20px;
    }

    .listLinks {
        margin-top:50px;
        ul {
            clear:left;
            margin-bottom:15px;
            height: 45px;

            li {
                float:left;
                vertical-align: middle;
                display: table-cell;
                padding:3px;
               // border-right:1px #d0d0d0 solid;
                width:113px;
                margin-left: 5px;
                @include box-shadow(1px, 2px, 1px, #ececec);


                &:first-child {


                }
                &:last-child {
                    border: 0;

                }
                img {
                    float:left;
                    width: 28px;
                    height: 28px;
                }
                span {
                    float:left;
                    display: block;
                    @include reset-span();
                    color: #272727;
                    margin-top: 8px;
                    margin-left: px;
                    font-size: 12px;
                }
            }
        }
    }
}

.linksPage {
    .newspaperList {
        li {
            margin-left: 0;
            margin-right: 7px;
            margin-bottom: 40px;
            &:nth-child(5n) {
                margin-right: 0;
            }
            width: 190px;
            height: 305px;
            a {
                .NewsPaperTitle {
                    left: 28px;
                    color: #000;
                }
                .date {
                    font-size:11px;
                    color: rgba(0, 0, 0, 0.55);
                }
            }
            .image {
                width: 160px;
                height: 255px;
                overflow: hidden;
                position: relative;
                margin: 0 auto;

            }
            img {
                width: 185px;
                height: 260px;
                margin-top: -10px;
                overflow: hidden;
                position: absolute;
                left: -8px;
            }
        }
    }
}

.tvList {
    li {
        float:left;
        margin: 9px;
        border :1px #f1f1f1 solid;

        &:hover {
            border :1px #dbdbdb solid;
        }
        a {
            width: 220px;
            height: 170px;
            img {
                width: 220px;
            }
            span {
                background: none;
            }
        }
    }
}