@font-face {
	font-family: "icons";
	src: url('../fonts/icons/icons.eot');
	src: url('../fonts/icons/icons.eot?#iefix') format('eot'),
		url('../fonts/icons/icons.woff') format('woff'),
		url('../fonts/icons/icons.ttf') format('truetype'),
		url('../fonts/icons/icons.svg#icons') format('svg');
}

%font {
	font-family: "icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
	vertical-align: middle;
	display: inline-block;
}

@function font-char($filename) {
	$char: "";

	@if $filename == arrow-up {
		$char: "\E001";
	}
	@if $filename == cal {
		$char: "\E002";
	}
	@if $filename == cancel {
		$char: "\E003";
	}
	@if $filename == edit {
		$char: "\E004";
	}
	@if $filename == facebook {
		$char: "\E005";
	}
	@if $filename == fb {
		$char: "\E006";
	}
	@if $filename == horizontal {
		$char: "\E007";
	}
	@if $filename == left-btn {
		$char: "\E008";
	}
	@if $filename == menu {
		$char: "\E009";
	}
	@if $filename == menu2 {
		$char: "\E00A";
	}
	@if $filename == plus {
		$char: "\E00B";
	}
	@if $filename == right-btn {
		$char: "\E00C";
	}
	@if $filename == searchi {
		$char: "\E00D";
	}
	@if $filename == sunny {
		$char: "\E00E";
	}
	@if $filename == twitter {
		$char: "\E00F";
	}
	@if $filename == vertical {
		$char: "\E010";
	}

	@return $char;
}

@mixin font($filename, $insert: before) {
	&:#{$insert} {
		@extend %font;
		content: font-char($filename);
	}
}

.font-arrow-up {
	@include font(arrow-up);
}
.font-cal {
	@include font(cal);
}
.font-cancel {
	@include font(cancel);
}
.font-edit {
	@include font(edit);
}
.font-facebook {
	@include font(facebook);
}
.font-fb {
	@include font(fb);
}
.font-horizontal {
	@include font(horizontal);
}
.font-left-btn {
	@include font(left-btn);
}
.font-menu {
	@include font(menu);
}
.font-menu2 {
	@include font(menu2);
}
.font-plus {
	@include font(plus);
}
.font-right-btn {
	@include font(right-btn);
}
.font-searchi {
	@include font(searchi);
}
.font-sunny {
	@include font(sunny);
}
.font-twitter {
	@include font(twitter);
}
.font-vertical {
	@include font(vertical);
}