.btn
{
    display: inline-block;
    background-color: $buttonColor;
    color: #FFF;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    border-radius: $borderRadius;
    line-height: 34px;
    padding: 0 20px;
    border: none;
    outline: none;
    @include transition(all,.2s);
    cursor: pointer;

    &.disabled
    {
        @include opacity(.5);
        cursor: auto;

        &:hover
        {
            background-color: $buttonColor;
        }
    }

    &.text
    {
            padding: 0;
            line-height: normal;
            background-color: transparent !important;
            color: $buttonColor !important;

            &:hover
            {
                    text-decoration: underline;
            }
    }


    &.fluid
    {
            width: 100%;
    padding: 0;
    }

    &:hover,&:focus
    {
            background-color: darken($buttonColor,5%);
            color: #FFF;
            text-decoration: none;
    }

    &:active
    {
            background-color: darken($buttonColor,10%);
            @include transition(none);
    }

    &.line
    {
        background-color: transparent;
        color: $buttonColor;
        border: 1px solid $buttonColor;
        line-height: 32px;

        &:hover
        {
            color: #FFF;
            background-color: $buttonColor;
        }
    }
    
    &.mini
    {
        font-size: 12px;
        line-height: 26px;
        padding: 0 14px;
            
    }
}

#moreBtn{
    font-size:16px;
    font-family: $helveticaLT;
    font-weight: bold;
    display: inline-block;
    .font-arrow_bold{
        font-size:12px;
        padding:0 10px;
    }
}